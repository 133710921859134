<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
            v-model="query.custType"
            clearable
            placeholder="代理商类别"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option
              v-for="item in ibTypeOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>
    <el-select
            v-model="query.custLvl"
            clearable
            filterable
            placeholder="代理级别"
            class="filter-item"
            style="width: 130px"
            @change="custLvlChange"
    >
      <el-option
              v-for="(item,index) in ibLevelList"
              :key="index"
              :label="item.levelName"
              :value="item.id"
      >
      </el-option>
    </el-select>
     <el-select
             v-model="query.ibRank"
             clearable
             placeholder="代理层级"
             class="filter-item"
             style="width: 130px"
             @change="toQuery"
     >
      <el-option
              v-for="item in ibRankOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>
    <el-select @change="toQuery" v-model="query.custStatus" clearable placeholder="代理状态" class="filter-item" style="width: 130px">
      <el-option v-for="item in cusStatusOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
     <el-select @change="toQuery" v-model="query.auditStatus" clearable placeholder="审核状态" class="filter-item" style="width: 130px">
      <el-option v-for="item in ibStatusOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>

    <el-input v-model="query.basicInfo" clearable placeholder="代理姓名/ID/手机/邮箱" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.belongInfo" clearable placeholder="上级姓名/ID" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.managerInfo" clearable placeholder="客户经理姓名/ID" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <div class="filter-item">
      审核通过时间：
      <el-date-picker
        style="width: 150px;"
        v-model="query.startAuditTime"
        type="date"
        placeholder="开始日期">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="query.endAuditTime"
        type="date"
        placeholder="结束日期">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-close" @click="clearHandle">清空</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
import { queryMerchantCommissionLevel } from '../../../api/ib/tManager'
import { mapGetters } from 'vuex'
export default {
  components: { eForm },
  props: {
    query: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      ibLevelList: []
    }
  },
  created(){
    this.getIbList();
  },
  computed: {
    ...mapGetters([
      'ibTypeOptions',
      'ibStatusOptions',
      'cusStatusOptions',
      'ibRankOptions',
    ])
  },
  methods: {
    checkPermission,
    custLvlChange(e){
      if(!e){
        this.$store.commit('setMerchantCustLvl','');
        this.$emit('custLvlClearHandle',e);
      }else {
        this.toQuery();
      }
    },
    toQuery() {
      this.$emit('toQuery');
    },
    getIbList(){
      queryMerchantCommissionLevel({
        custId: 0,
        size: 10000,
      }).then((res) => {
        this.ibLevelList = res.content;
      })
    },
    clearHandle(){
      this.$emit('clear');
    }
  }
}
</script>

import request from '@/utils/request'

export function addWithGoldRule(data) {
  return request({
    url: '/crm/tCustBonusRule',
    method: 'post',
    data
  })
}
/*对应/crm/tCustBonusRule,增加规则,多传一个id*/
export function editWithGoldRule(data) {
  return request({
    url: '/crm/tCustBonusRule',
    method: 'put',
    data
  })
}
/*对应/crm/tCustBonusRule,增加规则,多传一个id*/
export function deleteWithGoldRule(data) {
  return request({
    url: `/crm/tCustBonusRule/${data.id}`,
    method: 'delete',
    data
  })
}

export function startWithGoldRule(data) {
  return request({
    url: '/crm/updateBonusRuleStatus',
    method: 'put',
    data
  })
}


export function queryMt4TradeSymbolList() {
  return request({
    url: 'crm/tMt4TradeSymbolNoPage',
    method: 'get',
  })
}
export function queryEditMt4TradeSymbolList(data) {
  return request({
    url: '/crm/ruleExcludeSymbol',
    method: 'get',
    params: data,
  })
}

// 赠金活动查看
export function queryViewMt4TradeSymbolList(data) {
  return request({
    url: '/crm/tCustBonusRuleById',
    method: 'get',
    params: data,
  })
}


// 赠金管理方面start
//  0-审核拒绝 1-审核通过 7-取消 3-取消冻结
export function updateGiveGoldStatus(data) {
  return request({
    url: '/crm/updateTCustBonusActStatus',
    method: 'put',
    data,
  })
}

//  信用额审核页面的,通过和 拒绝 1-已通过 2-已拒绝
export function updateCreditsStatus(data) {
  return request({
    url: '/crm/tCustBonusMt4',
    method: 'put',
    data,
  })
}

export function applyTranferd(data) {
  return request({
    url: '/crm/updateGoldYield',
    method: 'get',
    params:data,
  })
}

//赠金审核中客户的可参与活动的账户下拉菜单
export function getAccountList(data){
  return request({
    url: '/crm/bonusAct/getLogins',
    method: 'get',
    params: data,
  })
}

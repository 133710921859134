<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" title="添加客户" width="500px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="80px">
      <el-form-item label="机构名称">
        <el-input v-model="form.fullname" style="width: 370px;" :disabled="true"/>
      </el-form-item>
      <el-form-item label="客户">
        <el-select v-model="form.customerId" filterable placeholder="请选择客户">
          <el-option
            v-for="item in cusList"
            :key="item.key"
            :label="item.display_name"
            :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/ib/ibMerchantInfo'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  created(){
    this.getCustomers()
  },
  data() {
    return {
      loading: false, dialog: false,rules:[],cusList:[],
      form: {
        id: '',
        fullname:'',
        customerId:''
        
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    getCustomers(){
      //获取客户列表
    },
    doSubmit() {
      this.loading = true
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },    
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        fullname:'',
        customerId:''
      }
    }
  }
}
</script>

<style scoped>

</style>

import request from '@/utils/request'

export function queryFromEmailList() {
  return request({
    url: 'crm/queryEmailConfig',
    method: 'get',
  })
}

export function queryRsAllEMail(data) {
  return request({
    url: 'crm/queryMailToList',
    method: 'get',
    params: data,
  })
}


export function queryEMailTemplateList() {
  return request({
    url: 'crm/queryBriefMailTemplate',
    method: 'get',
  })
}

export function queryEMailOrMessageDetail(){
  return request({
    url: 'crm/queryBriefMailTemplate',
    method: 'get',
  })
}

export function queryMassDetail(data){
  return request({
    url: 'crm/queryMailGroup',
    method: 'get',
    params: data,
  })
}

export function addMass(data){
  return request({
    url: 'crm/sysSendMailGroup',
    method: 'post',
    data,
  })
}


export function editMass(data){
  return request({
    url: 'crm/sysSendMailGroup',
    method: 'put',
    data,
  })
}
// 估算可能的收件人数
export function computedReceiversCount(data){
  return request({
    url: 'crm/sysSendMailGroup/estimateReceivers',
    method: 'post',
    data,
  })
}

export function deleteMass(data){
  return request({
    url: 'crm/mailGroupDelete',
    method: 'post',
    data,
  })
}

export function submitMass(data){
  return request({
    url: 'crm/mailGroupSubmit',
    method: 'post',
    data,
  })
}

export function updateMassStatus(data){
  return request({
    url: 'crm/mailGroupExamine',
    method: 'post',
    data,
  })
}

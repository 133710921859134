<template>
  <el-dialog
    :visible="dialogShow"
    :before-close="beforeCloseHandle"
    :title="computeTitle"
    append-to-body
  >
    <el-form
      :model="form"
      :rules="rules"
      :inline="true"
      ref="form"
    >
      <el-form-item
        v-if="clientInfo.ibRank == 2"
        prop="clientManageId"
        :label="translateTitle"
      >
        <el-select
          v-model="form.clientManageId"
          placeholder="请选择客户经理"
          filterable
        >
          <el-option
            v-for="(item,index) in ClientManageOption"
            :key="index"
            :label="item.userName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="clientInfo.ibRank == 1"
        prop="subAgency"
        :label="translateTitle"
      >
        <el-select
          v-if="clientInfo.ibRank == 1"
          v-model="form.subAgency"
          filterable
          remote
          reserve-keyword
          :remote-method="getSubAgencylist"
          :loading="searchLoading"
          placeholder="请输入上级代理号"
        >
          <el-option
            v-for="item in superAgencylist"
            :key="item.id"
            :label="item.userName +'(No:'+item.id+')'"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        prop="agentLevel"
      >
        <el-select
          v-model="form.agentLevel"
          placeholder="请选择代理级别"
        >
          <el-option
            v-for="(item,index) in agentLevelOption"
            :key="index"
            :label="item.levelName"
            :value="item.levelId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click.native="saveHandle">保存</el-button>
      </el-form-item>
    </el-form>
    <div
      v-if="getRuleLineFlag"
      class="rule-table">
      <div style="font-size: 20px;" class="table-title">返佣规则：</div>
      <!--头部-->
      <el-row
        class="first-row"
        v-for="(item,index) in ruleData.slice(0,1)"
        :key="index"
      >
        <el-col
          class="col-box  isfirst-row"
          :span="3"
        >账户类型</el-col>
        <el-col
          class="col-box isfirst-row"
          v-for="(headerItem,k) in item.symbolGroup"
          :key="k"
          :span="3"
        >{{getHeaderItem(headerItem)}}</el-col>
      </el-row>
      <el-row
        class="second-row"
        v-for="(item,index) in ruleData"
        :key="index"
      >
        <el-col
          class="col-box"
          :span="3"
        >{{item.mt4GroupNamme}}</el-col>
        <el-col
          class="col-box"
          v-for="(colItem,k) in item.symbolGroup"
          :key="k"
          :span="3"
        >{{getColItem(colItem,k)}}</el-col>
      </el-row>
      <div class="no-data" v-if="!ruleData.length">暂无数据</div>
    </div>
  </el-dialog>
</template>

<script>
  import { initData } from '@/api/data'
  import { alterBelongInfo } from '@/api/cus/tCustInfo'
  import { queryLevleAndRules,querySubLevleAndRules } from '@/api/ib/tManager.js'
  import { mapGetters } from 'vuex';
  export default {
    name: "tranferClientTypeDialog",
    props: {
      dialogShow: {
        type: Boolean,
        default: false
      },
      clientInfo:{
        type: Object,
        default(){
          return {}
        }
      }
    },
    data() {
      return {
        form:{
          clientManageId: '',
          agentLevel: '',
          subAgency: '',
        },
        rules:{
          clientManageId:[
            { required: true, message: '请选择代客户经理', trigger: 'change' }
          ],
          agentLevel:[
            { required: true, message: '请选择代理级别', trigger: 'change' }
          ],
          subAgency:[
            { required: true, message: '请选择归属代理', trigger: 'change' }
          ],
        },
        ClientManageOption: [],
        agentLevelOption: [],
        superAgencylist: [],
        searchLoading: false,
        ruleData: [
          /*{
            accountNamme: 'STD',
            symbolGroup:[
              {
                name: 'Forex',
                value: '5 $/lot'
              },
              {
                name: 'GOLD',
                value: '3 pips/lot'
              },
              {
                name: 'SUVKER',
                value: '2 pips/lot'
              },
              {
                name: 'Oil',
                value: '0.2 $/lot'
              },
              {
                name: 'INDEX',
                value: '1 $/lot'
              },
            ]
          },
          {
            accountNamme: 'PRO',
            symbolGroup:[
              {
                name: 'Forex',
                value: '5 $/lot'
              },
              {
                name: 'GOLD',
                value: '3 pips/lot'
              },
              {
                name: 'SUVKER',
                value: '2 pips/lot'
              },
              {
                name: 'Oil',
                value: '0.2 $/lot'
              },
              {
                name: 'INDEX',
                value: '1 $/lot'
              },
            ]
          },
          {
            accountNamme: 'ECN',
            symbolGroup:[
              {
                name: 'Forex',
                value: '5 $/lot'
              },
              {
                name: 'GOLD',
                value: '3 pips/lot'
              },
              {
                name: 'SUVKER',
                value: '2 pips/lot'
              },
              {
                name: 'Oil',
                value: '0.2 $/lot'
              },
              {
                name: 'INDEX',
                value: '1 $/lot'
              },
            ]
          },*/
        ]
      }
    },
    computed: {
      computeTitle() {
        let words = this.clientInfo.ibRank == 1 ? '子' : '主' ;

        return `转移为${words}代理`;
      },
      translateTitle(){
        return this.clientInfo.ibRank == 1
          ? '归属代理转移'
          : '客户经理转移';
      },
      getRuleLineFlag(){
        let flag = false;
        if(this.clientInfo.ibRank == 1 && this.form.agentLevel ){
          flag = true;
        }
        return flag;
      }
    },
    watch:{
      'form.clientManageId'(newValue,oldValue){
        if(newValue&&this.dialogShow){
          this.getSubLevelAndRule(0);
        }
      },
      'form.subAgency'(newValue,oldValue){
        if(newValue&&this.dialogShow){
          this.getSubLevelAndRule(newValue);
        }
      },
      'form.agentLevel'(newValue,oldValue){
        if(newValue){
          this.getRuleList(newValue);
        }
      },
      clientInfo:{
        handler(newValue,oldValue){
          if(newValue && this.dialogShow == true){
            this.$nextTick(() => {
              this.formInit()
            })
          }
        },
        deep: true
      },
      dialogShow(){
        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        })
      }
    },
    created(){
      this.getManagerList()
    },
    methods: {
      formInit(){
        this.form = {
          clientManageId: '',
          agentLevel: '',
          subAgency: '',
        }
        this.ruleData = [];
      },
      getManagerList(){
        const url = 'crm/tManager'
        const params = { page: 0, size: 500}
        initData(url, params).then(res => {
          this.ClientManageOption = res.content
        }).catch(err => {
        });
      },
      close(){
        this.$emit('close-dialog')
      },
      beforeCloseHandle() {
        this.close();
      },
      saveHandle(){
        this.$refs.form.validate((valid) => {
          console.log(valid)
          if(valid){
            let params = {
              custId: this.clientInfo.id,
              levelId: this.form.agentLevel,
              oprType: this.clientInfo.ibRank == 1 ? 4: 5,
            };
            if(this.clientInfo.ibRank == 1){
              params.merId = this.form.subAgency;
            }else {
              params.managerId = this.form.clientManageId;
            }
            alterBelongInfo(params).then((res) => {
              this.$notify({
                message: '修改成功',
                type: 'success'
              });
              this.close();
              this.$parent.init();
            })
          }else {
            console.log('error submit');
            return false;
          }
        })
      },
      getSubAgencylist( query ){
        const url = 'crm/tCustInfo';
        const params = {  custTypeStr:'1,2',basicInfo:query, page: 0, size: 500}
        this.searchLoading = true
        initData(url, params).then(res => {
          this.superAgencylist = res.content
          setTimeout(() => {
            this.searchLoading = false
          }, this.time)
        }).catch(err => {
          this.searchLoading = false
        })
      },
      //返佣规则
      getHeaderItem(headerItem){
        return headerItem.name;
      },
      getColItem(colItem){
        return colItem.value == -1 ? '/' : colItem.value;
      },
      getRuleList(levelId){
        queryLevleAndRules({
          levelId: levelId
        }).then((res) => {
          this.ruleData = res;
        })
      },
      // 代理级别
      getSubLevelAndRule(custId){
        querySubLevleAndRules({
          custId: custId
        }).then((res) => {
          this.agentLevelOption = res;
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  .rule-table {
    padding: 10px;
    .isFirst {
      /*color: transparent;*/
    }

    .table-title {
      margin-bottom: 10px;
    }
    .col-box {
      height: 36px;
      line-height: 36px;
      text-align: center;
      border: 1px solid #cccccc;
      border-top: none;
      border-right: none;
      &.isfirst-row {
        border-top: 1px solid #cccccc;
      }
      &:last-of-type {
        border-right: 1px solid #cccccc;
      }
    }
  }
</style>
